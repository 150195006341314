.card-background-container {
  background: #fff;
  border-radius: 20px;
  padding: 25px;
}

.card-background-container h4 {
  margin-bottom: 15px;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-tooltip,
.custom-tooltip:active,
.custom-tooltip:focus-visible,
.custom-tooltip:focus-within,
.custom-tooltip:hover,
.custom-tooltip:target,
.custom-tooltip:focus {
  outline: none;
}
