.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.pie-chart-text-container {
  margin-top: -340px;
  text-align: center;
  padding-bottom: 6px;
}

.pie-chart-detail-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  margin-top: 200px;
  justify-content: center;
}

.pie-chart-detail {
  display: flex;
  gap: 10px;
  align-items: center;
}

.color-container {
  height: 25px;
  width: 35px;
}

.project-card-icon-container img {
  background-color: #fff;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  border: 2px solid #fff;
}

.profile-image {
  height: inherit;
  width: inherit;
  object-fit: contain;
  border-radius: 50%;
}

.required-text {
  color: #ff0000;
}

.refresh-button {
  padding: 10px 20px;
  background-color: transparent;
  border: 1px solid #8594ac;
  color: #8594ac;
  font-weight: 600;
  border-radius: 20px;
  cursor: pointer;
  flex: none;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 2px;
}

.card-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 14px;
  margin-top: 10px;
  text-align: justify;
}

.border-left {
  border-left: 1px solid #000;
}

.border-bottom {
  border-bottom: 1px solid #000;
}
