.main-container {
    width: fit-content;
}

.card-client {
    background: #ffffff;
    border-radius: 16px;
    padding: 1rem 1.5rem;
    width: 100%;
}

.card-client-details {
    background: #f7f9fb;
    border-radius: 16px;
    padding: 1rem 1.5rem;
    height: fit-content;
    max-width: 310px;
}

.card-status {
    background: #ffffff;
    border-radius: 10px;
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-status-head {
    font-size: 24px;
    font-weight: 500;
}

.card-status-text {
    font-size: 12px;
}

.card-status-subText {
    font-size: 20px;
    font-weight: 400;
}

.card-status-image {
    height: 100px;
    width: 100px;
}

.card-status-text-data {
    font-size: 16px;
}

.chart-container {
    display: flex;
    justify-content: center;
    padding-top: 32px;
}

.card-bar-chart {
    /* rotate: 90deg; */
}

.button-container {
    margin-top: 24px;
    width: 100%;
}

.custom-tooltip {
    background: white;
    padding: 16px;
    border-radius: 16px;
    border: none;
}

.chart-container {
    /* display: flex; */
}

.chart-detail {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.chart-detail-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-top: 17px;
}

.chart-detail-data:first-child {
    margin-top: 0;
}

.dot {
    border-radius: 50%;
    height: 6px;
    width: 6px;
}

.detail {
    display: flex;
    gap: 5px;
    align-items: center;
}

.ul {
    width: 55%;
}

.secondary-container {
    display: flex;
    align-items: center;
}

.header-logo {
    height: 37px;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {

    .secondary-container {
        flex-direction: column;
    }
}